// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .CalendarDays
.CalendarDays {
  text-transform: capitalize;
  font-weight: $font-weight-bold;
  color: $lightpurple;
  font-size: 1.2rem;
}
