// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .DateCard
.DateCard {
  font-weight: $font-weight-bolder;
  min-width: 8rem;

  .card-header {
    padding: 0.5rem !important;
  }
  .card-body {
    padding: 0.5rem !important;
  }
  .day {
    font-size: $font-size-xl;
  }
  .year {
    font-weight: $font-weight-base;
  }
}
