// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .StatusSelectFilter
.StatusDropdown {
  display: flex;
  width: 75%;
  
  .DropdownToggle {
    width: 75%;
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
  }
}
