// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// MIXINS
// --------------------------------------------------

@mixin topbar-compact {
  .Section {
    height: unset;
  }
  .Figure {
    width: 3rem;

    .FigureAcronyme {
      font-size: 1.2rem;
    }
  }
  .TopbarUserInfo .name {
    height: 0;
    width: 0;
  }
  .Button {
    height: 3rem;
    width: 3rem;

   
    .Badge {
      top: -0.3rem;
      right: -0.3rem;

      width: 1.2rem;
      height: 1.2rem;
      font-size: 0.8rem;
    }
  }
  .span {
    display: none;
  }
}

// PROJECT STYLES
// --------------------------------------------------

// .Topbar
.Topbar {

  #Calque_2{
    transform: scale(0.85);
  }

  .Section {

    border-bottom-left-radius: 1.2rem;
    height: 11.5rem;
    padding: 0;
    transition: $transition-base;

    .Container {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }

    .search-submit {
      display: contents;
    }
  }
  .baseline {
    flex-grow: 1;
    align-self: flex-start;
    font-weight: $font-weight-small;
    font-size: large;
  }
  .Figure {
    margin-left: $spacer-base;
    width: 6rem;
    align-self: auto;
    transition: $transition-base;

    .FigureAcronyme {
      transition: $transition-base;
    }
  }
  .TopbarUserInfo .name {
    overflow: hidden;
    transition: $transition-base;
    height: $font-size-base * $line-height-base;
    margin: 0;
    width: auto;
  }
  .Button {
    position: relative;
    padding: 0;
    height: 5rem;
    width: 5rem;
    border-radius: $border-radius-pill !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: $spacer-base;
    transition: $transition-base;

    .Icon {
      font-size: 2.8rem;
      transition: $transition-base;
    }
    .Badge {
      position: absolute;
      top: 0;
      right: 0;
      width: 1.6rem;
      height: 1.6rem;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      transition: $transition-base;
    }
  }
  .search-form {
    width: 100%;

    .InputGroup {
      margin-right: 2rem;
      width: 90%;

      .Icon {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        margin-top: -1.6rem;
        font-size: 2rem;
        color: $primary;
        z-index: 3;
      }
    }
  }
  &.compact {
    @include topbar-compact();
  }
  @include media-breakpoint-down(sm) {
    @include topbar-compact();

    .baseline {
      display: none;
    }
  }
}
