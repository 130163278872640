// CONFIG (NO SELECTORS GENERATED)
// TODO : @NICO : remove config when size limit of compiler will bi resolved
@import 'src/styles/config/Config.scss';

// IMPORT BOOTSTRAP BASE
// --------------------------------------------------

// FORMS
@import 'bootstrap/scss/forms';

// PROJECT STYLES
// --------------------------------------------------

// .form-group
.form-group {
  & > .Button {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

// .form-control
.form-control {
  background-color: $body-bg;
  box-shadow: $box-shadow-input;
  transition: $transition-base;

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  //&:disabled, (disabled is handled by the default bootstrap behaviour)
  &[readonly] {
    color: currentColor;
    background: transparent;
    border-color: transparent;
    box-shadow: none !important;
    padding-left: 0;
    opacity: 1;
    font-weight: $font-weight-bold;
  }
  &,
  &[readonly] {
    &::placeholder {
      color: $lightgrey;
      font-weight: $font-weight-normal;
    }
  }
  &:disabled,
  &[readonly] {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
  }
}
