// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// DEDICATED STYLES
// --------------------------------------------------

// .VideosViewerList
.VideosViewerList {
  @include media-breakpoint-up(md) {
    .VideoGridListContainer {
      position: relative;
    }
    .VideoGridListWrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      overflow-y: auto;
    }
    // display: grid;
    // grid-template-columns: 3fr 1fr;
    // grid-template-rows: 1fr;
    // gap: 0px 0px;
    // grid-template-areas: 'VideosGridMain VideosGridList';

    // .VideosGridMain {
    //   grid-area: VideosGridMain;
    // }
    // .VideosGridList {
    //   grid-area: VideosGridList;

    //   overflow: auto;
    // }
    .Overlay {
      .Icon {
        height: 2rem;
        font-size: 2rem;
      }
    }
  }
}
