// IMPORT BOOTSTRAP FUNCTIONS
// --------------------------------------------------
@import 'bootstrap/scss/_functions.scss';

// PROJECT FUNCTIONS
// --------------------------------------------------

// @mixin(function) _assert-ascending
@mixin _assert-ascending($map, $map-name) {
  // OVERIDE EMPTY FUNCTION TO DISABLE ASSERT-ASCEBNDING NOT NEEDED FOR OUR BREAPOINTS VERIFICATION !
}

// @function strip-unit
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// @function px-to-rem
@function px-to-rem($value) {
  $px: strip-unit($value);

  //Default font size on html element is 16px for 100% ($global-ratio);
  $rem: $value / $global-ratio * 1rem;

  @return $rem;
}

// @function rem-to-px
@function rem-to-px($value) {
  $rem: strip-unit($value);

  //Default font size on html element is 16px for 100% ($global-ratio) ;
  $px: $value * $global-ratio * 1px;

  @return $px;
}

// @function color-yiq-test
@function color-yiq-test($color, $dark: $yiq-text-dark, $light: $yiq-text-light) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return 'dark';
  } @else {
    @return 'light';
  }
}

// @function container-max-width
@function container-max-width($width, $additional-padding, $padding: $grid-gutter-width-base) {
  $value: 0;
  $value: $width + ($padding) + (2 * $additional-padding);

  @return $value;
}
