// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// MeetingStatutes

.cancel-margin {
  margin: 0 !important;
  padding: 0 !important;
  
  .MeetingStatutes {
    text-align: center;
    
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    
    padding: 0.1rem 2rem;
    margin-left: $spacer-base * 4;
    
    display: inline-block;
    
    .meetingStatusText {
      font-size: $font-size-base * 1.1;
    }
  }
}
