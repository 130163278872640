// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .ParticipantCard
.ParticipantCard {
  height: 100%;
  .card-body {
    margin: 0;
    overflow: visible;
  }
}
