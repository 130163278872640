// DEFAULT VARIABLES
// --------------------------------------------------

// Global ratio
$global-base-size: 16px; // default navigator font-size
$global-base-ref: 10px; // default px reference for 1rem
$global-font-size: 14px; // default px equivalence for font-size-base

$global-percent: strip-unit($global-base-ref / $global-base-size) * 100%;

// Fonts
$font-family-sans-serif: 'Free Sans', 'Roboto', sans-serif;

// Font-weight
$font-weight-light: 200;
$font-weight-small: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// Font-sizes
$font-size-base: strip-unit($global-font-size / $global-base-ref) * 1rem;
$font-size-sm: $font-size-base * 0.8;
$font-size-md: $font-size-base;
$font-size-lg: $font-size-base * 1.2;
$font-size-xl: $font-size-base * 1.5;

$h1-font-size: 3.6rem;
$h2-font-size: 2.6rem;
$h3-font-size: 1.8rem;
$h4-font-size: 1.6rem;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

// Colors
$white: #ffffff;
$ultralightgrey: #f7f9fd;
$lightgrey: #d3d8e0;
$mediumgrey: #efefef;
$darkgrey: #5a5c5f;
$ultradarkgray: #1d1d1d;
$purple: #6e196e;
$darkpurple: #280928;
$lightpurple: #950d61;
$red: #f11111;
$green: #0bc98e;
$darkblue: #1e3764;
$darkgrayblue: #353756;
$orange: #f09123;
$pink: #d2145a;
$inactive:#747d93;

// Theme's Colors
$primary: $darkblue;
$secondary: $pink;

$greylight: #BEBEBE;

// meeting status
$meeting-absent: #d4105b;
$meeting-pending: #016db0;
$meeting-present: #81a42e;
$meeting-not-sent: #5a5c5f;

// status
$refused: $red;
$pending: $orange;
$accepted: $green;


$light: $ultralightgrey;
$medium: $mediumgrey;
$dark: $darkpurple;

$text-title: $primary;
$text-simple: $dark;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'light': $light,
  'medium': $medium,
  'dark': $dark,
  'meeting-absent': $meeting-absent,
  'meeting-pending': $meeting-pending,
  'meeting-present': $meeting-present,
  'meeting-not-sent': $meeting-not-sent,
  'refused': $refused,
  'pending':$pending,
  'accepted':$accepted,
  'orange': $orange,
  'simple': $text-simple,
  'title': $text-title,
);

$theme-colors-editorial: (
  'lightgrey': $lightgrey,
  'mediumgrey': $mediumgrey,
  'darkgrey': $darkgrey,
  'purple': $purple,
  'lightpurple': $lightpurple,
  'red': $red,
  'green': $green,
  'darkblue': $darkblue,
  'darkgrayblue': $darkgrayblue,
  'pink': $pink,
);

$body-bg: $white;
$body-bg-alt: $light;
$body-color: $primary;


$text-muted: $darkgrey;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $body-color;
$yiq-text-light: $body-bg;

// Mobile
$mobile-font-size: 14px;
$mobile-title-font-size: 1.5rem;

// Grid columns
$grid-gutter-width-base: 24px;
$grid-gutter-widths: (
  no: 0,
  xs: 2px,
  sm: $grid-gutter-width-base * 0.25,
  md: $grid-gutter-width-base * 0.5,
  lg: $grid-gutter-width-base * 1,
  xl: $grid-gutter-width-base * 1.5,
);

// Grid columns gutters ratios
$grid-gutter-max-widths: (
  xs: map-get($grid-gutter-widths, md),
  sm: map-get($grid-gutter-widths, md),
  md: map-get($grid-gutter-widths, md),
  lg: map-get($grid-gutter-widths, xl),
  xl: map-get($grid-gutter-widths, xl),
);

// Grid containers additional paddings
$container-additional-padding-base: 24px;
$container-additional-paddings: (
  xs: $container-additional-padding-base * 1/2,
  sm: $container-additional-padding-base * 1/2,
  md: $container-additional-padding-base * 1/2,
  lg: $container-additional-padding-base,
  xl: $container-additional-padding-base,
);

// Grid containers
$container-appearence-xl: 1200px;
$container-max-width-xl: container-max-width($container-appearence-xl, map-get($container-additional-paddings, xl));
$container-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: $container-max-width-xl,
);

// Grid breakpoints
// ATTENTION : si changement, changer les variables dans style.js
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: $container-max-width-xl,
);

// Breakpoint Configuration
$breakpointNavbarExpandable: md;

// Spacer
$spacer-base: 1rem;
$spacer-section: 4rem;

// Border
$border-base: 0.2rem;
$card-border-radius: 7px;

// Border-radius
$border-radius-sm: 0.3rem;
$border-radius-md: 0.6rem;
$border-radius-lg: 1.2rem;
$border-radius-xl: 3rem;

$border-radius-map: (
  'none': 0,
  'sm': $border-radius-sm,
  'md': $border-radius-md,
  'lg': $border-radius-lg,
  'xl': $border-radius-xl,
);

$border-radius: $border-radius-md;
$border-radius-pill: 10rem;

// Box-shadow
$box-shadow-colors: (
  'primary': $primary,
  'dark': $dark,
);
$box-shadow-alphas: (
  'light': 0.1,
  'medium': 0.3,
  'strong': 0.5,
);
$box-shadow-sizes: (
  'sm': 0 0 0.4rem 0.1rem,
  'md': 0 0 0.8rem 0.2rem,
  'lg': 0 0 1rem 0.1rem,
);
$box-shadow-input: 0 0.2rem 0.4rem rgba($dark, 0.1);
$box-shadow-hover: 0 0.4rem 0.8rem rgba($secondary, 0.1);
$box-shadow-navbar: 0 0.2rem 0.4rem rgba($dark, 0.5);

// Alpha
$alpha-base: 0.5;
$alpha-base-hover: 0.75;

// Transition
$transition-base: all 0.2s ease-in-out;

// Variants
$variant-bg-hover: 5%;
$variant-text-hover: 15%;

// Icon
$icon-size: 1em;
$icon-sizes: (
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
);

// Figure
$figure-icon-size: 4rem;

// Figure Ratios
$figure-ratios: (
  '2-3': 2/3,
  '3-4': 3/4,
  '1-1': 1/1,
  '4-3': 4/3,
  '3-2': 3/2,
  '16-9': 16/9,
);

// Figure Occupation
$figure-occupations: (
  '50': 50%,
  '60': 60%,
  '70': 70%,
  '80': 80%,
  '90': 90%,
);

// Badge
$badge-font-size: 0.8em;
$badge-padding-x: 0.5em;
$badge-padding-y: 0.5em;
$badge-pill-padding-x: $badge-padding-x + $badge-padding-y;

$badge-border-radius: $border-radius-sm;

$badge-font-size-sm: $font-size-sm;
$badge-padding-x-sm: 0.5rem;
$badge-padding-y-sm: 0.5rem;
$badge-font-size-md: $font-size-base;
$badge-padding-x-md: 1rem;
$badge-padding-y-md: 0.75rem;
$badge-font-size-lg: $font-size-lg;
$badge-padding-x-lg: 1.5rem;
$badge-padding-y-lg: 1rem;

// Button
$btn-padding-x-sm: 1rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.75rem;
$btn-padding-x-lg: 2rem;
$btn-padding-y-lg: 1rem;

// Card
$card-spacer-x-sm: 1rem;
$card-spacer-y-sm: 1rem;
$card-spacer-x: 2rem;
$card-spacer-y: 2rem;
$card-spacer-x-lg: 4rem;
$card-spacer-y-lg: 3rem;

// Figure
$figure-icon-size: 5rem;

// Dropdown
$dropdown-padding-x: 1.5rem;
$dropdown-padding-y: 0.75rem;

// Modal
$modal-padding-x: 4rem;
$modal-padding-y: 2rem;

$modal-inner-padding: $modal-padding-y;

// Custom Inputs
$custom-control-indicator-border-width: 0.1rem;

$custom-control-indicator-size: 1.6rem;
$custom-checkbox-indicator-border-radius: $border-radius-sm;

// IMPORT BOOTSTRAP VARIABLES
// --------------------------------------------------
@import 'bootstrap/scss/variables';

// COMPLEMENT VARIABLES WITH BOOTSTRAP COMBINATION
// --------------------------------------------------

// Buttons
$btn-height: ($btn-font-size * $btn-line-height) + ($btn-padding-y * 2);
$btn-height-xs: 1rem;
$btn-height-sm: ($btn-font-size-sm * $btn-line-height-sm) + ($btn-padding-y-sm * 2);
$btn-height-lg: ($btn-font-size-lg * $btn-line-height-lg) + ($btn-padding-y-lg * 2);

//Blockquotes (SmartAgence)
$linear-gradient-background: linear-gradient(60deg, #6d2861, #6d2861 5%, #d3145b 25%, #c4583d 51%, #f29222);
$linear-gradient-after-title : linear-gradient(60deg, #d3145b, #d3145b 20%, #f29222 80%, #f29222);
$linear-gradient-ul-li: linear-gradient(60deg, #1d3765, #d3145b 55%, #c4583d 71%, #f29222);
