@import '../../../styles/config/Config';

.NotificationPopover > .popover {
  min-width: 350px;
  margin-right: 2rem;
  
  .PopoverBody {
    padding: 0.5rem;
    height: 400px;
    overflow: auto;
  }

  @media (max-width: 575px) {
    margin: 0;
    min-width: unset;
    width: 100%;
  }
}
