// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';
@import 'src/styles/config/_variables.scss';

// MIXINS
// --------------------------------------------------

@mixin makeNavbarExpandable {
  // .NavbarHead
  .NavbarHead {
    flex-direction: row;
    flex: 0 0 100%;
    overflow: hidden;
  }

  // .NavbarToggler
  .NavbarToggler {
    display: block;
  }
}

// PROJECT STYLES
// --------------------------------------------------

// .Navbar
.Navbar {
  min-height: 70px;
  min-width: 24rem;
  display: flex;
  flex-direction: column;
}

// .NavbarHead
.NavbarHead {
  display: flex;
  justify-content: flex-start;
  margin-left:50px;
  align-items: center;
  flex: 0 0 auto;
  overflow: hidden;
}

// .NavbarBody
.NavbarBody {
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow:scroll;
}

// .NavbarToggler
.NavbarToggler {
  display: none;
}

// Responsive
@include media-breakpoint-down($breakpointNavbarExpandable) {
  @include makeNavbarExpandable();
}
