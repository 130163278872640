// CONFIG (NO SELECTORS GENERATED)
// TODO : @NICO : remove config when size limit of compiler will bi resolved
@import 'src/styles/config/Config.scss';

// IMPORT BOOTSTRAP BASE
// --------------------------------------------------

// REBOOT
@import 'bootstrap/scss/reboot';

// PROJECT TYPES
// --------------------------------------------------

// html
html {
  font-size: $global-percent;
}
