// CONFIG (NO SELECTORS GENERATED)
// TODO : @NICO : remove config when size limit of compiler will bi resolved
@import 'src/styles/config/Config.scss';

// IMPORT BOOTSTRAP BASE
// --------------------------------------------------

// TRANSITION
@import 'bootstrap/scss/transitions';

// UTILITIES
@import 'bootstrap/scss/utilities';

// CLOSE
@import 'bootstrap/scss/close';

// PROJECT UTILITIES
// --------------------------------------------------

// [class*="bg-"]
@each $color, $value in $theme-colors {
  @include bg-variant-text('.bg-#{$color}', $value, true);
}
// [class*="bg-"]
@each $color, $value in $theme-colors-editorial {
  @include bg-variant('.bg-#{$color}', $value, true);
  @include bg-variant-text('.bg-#{$color}', $value, true);
}

// [class*="bg-gradient"]
@if $enable-gradients {
  @each $color, $value in $theme-colors {
    @include bg-variant-text('.bg-gradient-#{$color}', $value, true);
  }
}

// .box-shadow
.box-shadow-none, .btn.box-shadow-none, .btn.box-shadow-none:focus {
  box-shadow: none;
}

@each $color, $colorValue in $box-shadow-colors {
  @each $alpha, $alphaValue in $box-shadow-alphas {
    @each $size, $sizeValue in $box-shadow-sizes {
      .box-shadow-#{$color}-#{$alpha}-#{$size} {
        box-shadow: $sizeValue rgba($colorValue, $alphaValue);
      }
    }
  }
}

// .rounded
.rounded {
  border-radius: $border-radius-lg !important;
}

// .border-radius
@each $radius, $radiusValue in $border-radius-map {
  .border-radius-#{$radius} {
    border-radius: $radiusValue !important;
  }
}

// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

// Responsive
.desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

// Text
.text-underline {
  text-decoration: underline;
}

// Color
.text-absent {
  color: $meeting-absent;
}
.text-pending {
  color: $meeting-pending;
}
.text-present {
  color: $meeting-present;
}
.text-not-sent {
  color: $meeting-not-sent;
}
