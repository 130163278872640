// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

@mixin makeSideNavbarExpandable {
  // .SideNavbar
  .SideNavbar {
    box-shadow: $box-shadow-navbar;
    min-width: 25rem;
    background-color: white;
    z-index: $zindex-fixed;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 90%;
    transform: translateX(100%);
  }

  // .SideNavbarOpen
  .SideNavbarOpen {
    transform: translateX(0%);
  }

  // .Backdrop
  .Backdrop {
    display: block;
  }

  // .BackdropClosed
  .BackdropClosed {
    opacity: 0;
    pointer-events: none;
  }

  // .SideNavbarClose
  .SideNavbarClose {
    display: block;
  }
}

// PROJECT STYLES
// --------------------------------------------------

// .SideNavbar
.SideNavbar {
  flex: 1 0 auto;

  transition: $transition-base;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
}

// .Backdrop
.Backdrop {
  z-index: 100;
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: $transition-base;
}

// .SideNavbarClose
.SideNavbarClose {
  display: none;
  position: absolute;
  top: $spacer-base;
  right: $spacer-base;
  padding: $btn-padding-y-sm;
}

// Responsive
@include media-breakpoint-down($breakpointNavbarExpandable) {
  @include makeSideNavbarExpandable();
}
