// CONFIG (NO SELECTORS GENARATED)
@import 'src/styles/config/Config';

.search {
  .search-card {
    height: 37vh;
    overflow: scroll;
    .article-card {
      height: 30vh;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .no-result {
      height: 25vh;
      font-size: 1.7em;
      text-align: center;
    }
  }
}
