// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

.Header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: $spacer-base 0;
  white-space: nowrap;
  
  a {
    color: $secondary;
    font-weight: $font-weight-bold;
  }


  @include media-breakpoint-down(sm) {
    .title {
      font-size: $mobile-title-font-size;
    }
    a {
      font-size: 1rem;
    }
  }
}
