// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';
@import 'src/styles/config/_variables.scss';

// PROJECT STYLES
// --------------------------------------------------

// .ArticleContentHeader

@media screen and (min-width: 992px) {
  .ArticleContent {
    padding-left: 16%;
    padding-right: 16%;
  }
}
.ArticleContentHeader {
  .Figure {
    max-height: 50vh;
  }
  .ArticleDateAuthor {
    margin: $spacer-base 0 0 0;
  }
  .h1 {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.15;
    color: black;
    margin: $spacer-section 0 0 0;
    display: flex;
    justify-content: center;
  }
  .lead {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    margin: $spacer-section 0 0 0;
  }
  .h2 {
    margin: 0 0 0.5em;
    padding: 1.3em 0 0;
    clear: both;
    font-weight: 800;
    color: #4d2d5e;
  }
}

.customArticle {
  font-family: Source Sans Pro, sans-serif;
  text-align: left;
  box-sizing: border-box;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.8;
  width: 100%;
}

.customArticle h2 {
  margin: 0 0 0.5em;
  padding: 1.3em 0 0;
  clear: both;
  font-weight: 800;
  color: #4d2d5e;
  font-size: 3.69rem;
}


.image.image-style-align-left {
  margin:10px 50px;
  float: left;
  img {
    width: fill-available; //for chrome & others
    width: -moz-available; //for firefox
  }
}

.image.image-style-align-right {
  margin:10px 50px;
  float: right;
  img {
    width: fill-available; //for chrome & others
    width: -moz-available; //for firefox
  }
}

.image.image_resized {
  img {
    width: fill-available; //for chrome & others
    width: -moz-available; //for firefox
  }
}

.image.image-style-align-center {
  margin: 50px auto;
  img {
    width: fill-available; //for chrome & others
    width: -moz-available; //for firefox
  }
}

blockquote {
  display: table;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  align-items: center;
  font-size: 1.8rem;
  margin: 2em 0 3em;
  width: 100%;
  position: relative;
  padding: 39.6px 18px 7.2px 46.8px;
  $border: 3.5px;
  background: #f2f2f2;
  background-clip: padding-box;
  border: solid $border transparent;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border;
    border-radius: inherit;
    background: $linear-gradient-background;
  }
}

blockquote .image{
  max-width: 1040px;
}

blockquote h1,
blockquote h2,
blockquote h3 {
  margin: 0 0 1em !important;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 2.7rem;
  color: #000 !important;
  padding-bottom: 0.8em;
}

blockquote h1::after,
blockquote h2::after,
blockquote h3::after {
  width: 38px;
  height: 6px;
  background: $linear-gradient-after-title;
  content: '';
  display: block;
  bottom: 0;
  left: 0;
  z-index: 5;
  font-weight: 800;
  margin-top: 10px;
}

blockquote p {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 160%;
  color: #1e1e1e;
}

blockquote p a {
  color: #078cd9;
  text-decoration: underline;
  background-color: transparent;
  font-weight: 700;
  font-size: 1.8rem;
}

blockquote ul li::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.6em;
  left: 0;
  z-index: 5;
  background: $linear-gradient-ul-li;
}

blockquote ul li::marker {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.6em;
  left: 0;
  z-index: 5;
  background: $linear-gradient-ul-li;
}

.customArticle h3 {
  margin-top: 1.2em;
  margin-bottom: 0.6em;
  color: #384e73;
  font-size: 2.61rem;
  font-weight: 700;
  line-height: 1.15;
}

.customArticle ul {
  margin-bottom: 2em;
  padding-left: 2.2rem;
  list-style: none;
}

.customArticle ul li {
  padding-left: 1.7rem;
  position: relative;
  margin-bottom: 0.75em;
}

.customArticle ul li:before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.4em;
  left: 0;
  z-index: 5;
  background: $linear-gradient-ul-li;
}

.customArticle ul li ul {
  margin-top: 0;
  margin-bottom: 1em;
  padding-top: 0.4rem;
  padding-left: 2.2rem;
  list-style: none;
}

.customArticle ul li ul li {
  padding-left: 26px;
  font-weight: 400;
  margin-bottom: 0.2em;
}

.customArticle ul li ul li ul li:before {
  content: '\2022';
  font-family: Arial, sans-serif;
  font-size: 1.1em;
  line-height: 50%;
  color: #d3145b;
  background: transparent;
  width: 19px;
  height: 2px;
  position: absolute;
  left: 0;
  z-index: 5;
  display: block;
}

.customArticle ul li ul li:before {
  width: 19px;
  height: 2px;
  font-size: 1.5em;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  z-index: 5;
  background: l$linear-gradient-ul-li;
}

.customArticle ol {
  counter-reset: a;
  list-style: none;
  margin-left: 0;
  padding-left: 3.8rem;
  margin-bottom: 1em;
}

.customArticle ol li {
  position: relative;
  margin-bottom: 0.75em;
}

.customArticle ol li:before {
  counter-increment: a;
  content: counter(a) '. ';
  color: #c4583d;
  box-sizing: border-box;
}

.customArticle ol li strong {
  font-weight: bolder;
}

.customArticle ol li ol {
  margin-top: 0;
  margin-bottom: 1em;
  padding-top: 0.4rem;
}

.customArticle ol li ol li {
  margin-bottom: 0.2em;
  position: relative;
}

.customArticle ol li ol li:before {
  content: counter(a, lower-alpha) '. ';
  color: #d3145b;
  counter-increment: a;
}

.customArticle tr {
  border-bottom: 1px solid #d3145b;
}

.customArticle td {
  color: #1e1e1e;
  padding: 1.1em 2rem 0.9em 0;
  font-size: 1.6rem;
  line-height: 135%;
  vertical-align: top;
  font-weight: 400;
}

.reactMarkDown {
  font-family: Source Sans Pro, sans-serif;
  text-align: left;
  box-sizing: border-box;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.8;
}

.reactMarkDown p a {
  color: #078cd9;
  text-decoration: underline;
  background-color: transparent;
  font-weight: 700;
  font-size: 1.8rem;
}

.reactMarkDown h3 {
  margin-top: 1.2em;
  margin-bottom: 0.6em;
  color: #384e73;
  font-size: 2.61rem;
  font-weight: 700;
  line-height: 1.15;
}

.reactMarkDown ul {
  margin-bottom: 2em;
  padding-left: 2.2rem;
  list-style: none;
}

.reactMarkDown ul li {
  padding-left: 1.7rem;
  position: relative;
  margin-bottom: 0.75em;
}

.reactMarkDown ul li:before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.4em;
  left: 0;
  z-index: 5;
  background: $linear-gradient-ul-li;
}

.reactMarkDown ul li ul {
  margin-top: 0;
  margin-bottom: 1em;
  padding-top: 0.4rem;
  padding-left: 2.2rem;
  list-style: none;
}

.reactMarkDown ul li ul li {
  padding-left: 26px;
  font-weight: 400;
  margin-bottom: 0.2em;
}

.reactMarkDown ul li ul li ul li:before {
  content: '\2022';
  font-family: Arial, sans-serif;
  font-size: 1.1em;
  line-height: 50%;
  color: #d3145b;
  background: transparent;
  width: 19px;
  height: 2px;
  position: absolute;
  left: 0;
  z-index: 5;
  display: block;
}

.reactMarkDown ul li ul li:before {
  width: 19px;
  height: 2px;
  font-size: 1.5em;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  z-index: 5;
  background: $linear-gradient-ul-li;
}

.reactMarkDown ol {
  counter-reset: a;
  list-style: none;
  margin-left: 0;
  padding-left: 3.8rem;
  margin-bottom: 1em;
}

.reactMarkDown ol li {
  position: relative;
  margin-bottom: 0.75em;
}

.reactMarkDown ol li:before {
  counter-increment: a;
  content: counter(a) '. ';
  color: #c4583d;
  box-sizing: border-box;
}

.reactMarkDown ol li strong {
  font-weight: bolder;
}

.reactMarkDown ol li ol {
  margin-top: 0;
  margin-bottom: 1em;
  padding-top: 0.4rem;
}

.reactMarkDown ol li ol li {
  margin-bottom: 0.2em;
  position: relative;
}

.reactMarkDown ol li ol li:before {
  content: counter(a, lower-alpha) '. ';
  color: #d3145b;
  counter-increment: a;
}

.reactMarkDown tr {
  border-bottom: 1px solid #d3145b;
}

.reactMarkDown td {
  color: #1e1e1e;
  padding: 1.1em 2rem 0.9em 0;
  font-size: 1.6rem;
  line-height: 135%;
  vertical-align: top;
  font-weight: 400;
}

.reactMarkDown a.download-block {
  display: block;
  position: relative;
  margin: 1.3em 0 1em;
  border-top: 1px solid #929295;
  border-bottom: 1px solid #929295;
  padding: 0.7rem 0 0.4125em 6.2rem;
  color: #343a40;
  background-color: transparent;
  text-decoration: underline 0.15em rgba(0, 0, 0, 0);
}

.reactMarkDown a.download-block:hover span.download-block__title {
  text-decoration: underline;
}

.reactMarkDown a.download-block span.download-block__icon {
  display: block;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 0.8em;
  left: 0;
  z-index: 5;
}

.reactMarkDown a.download-block span.download-block__title {
  display: inline-block;
  font-weight: 400;
  font-size: 1.5rem;
  color: #25262c;
  max-width: 64%;
  box-sizing: border-box;
}

.reactMarkDown a.download-block span.download-block__underline {
  position: relative;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 1px;
  transition: background-size 0.3s;
  box-sizing: border-box;
}

.reactMarkDown a.download-block span.download-block__props {
  padding-top: 0.7rem;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #d3145b;
  float: right;
}

.reactMarkDown div.framed {
  display: table;
  width: 100%;
  margin: 2em 0 3em;
  padding: 7px;
  font-size: 1.8rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  background: $linear-gradient-background;
}

.reactMarkDown div.framed__inner {
  display: table-cell;
  vertical-align: middle;
  background-color: #f2f2f2;
  padding: 2.2em 1em 0.4em 2.6em;
}

.reactMarkDown div.framed h3 {
  margin: 0 0 1em !important;
  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: 2.7rem;
  color: #000 !important;
  padding-bottom: 0.8em;
}

.reactMarkDown div.framed h3::after {
  width: 38px;
  height: 6px;
  background: $linear-gradient-after-title;
  content: '';
  display: block;
  bottom: 0;
  left: 0;
  z-index: 5;
  font-weight: 800;
  margin-top: 10px;
}

.reactMarkDown div.framed p {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 160%;
  color: #1e1e1e;
}

.reactMarkDown div.framed ul li::before {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.6em;
  left: 0;
  z-index: 5;
  background: $linear-gradient-ul-li;
}

.reactMarkDown div.framed ul li::marker {
  content: '';
  display: block;
  width: 1rem;
  height: 1rem;
  position: absolute;
  top: 0.6em;
  left: 0;
  z-index: 5;
  background: $linear-gradient-ul-li;
}

.reactMarkDown h2 {
  margin: 0 0 0.5em;
  padding: 1.3em 0 0;
  clear: both;
  font-weight: 800;
  color: #4d2d5e;
  font-size: 3.69rem;
}

.reactMarkDown div.keyfigure {
  height: 100%;
  margin: 0 0 0;
  padding: 2em 2rem;
  background-color: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.25);
  color: #1e1e1e;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.8;
  text-align: left;
}

.reactMarkDown div.keyfigure div.keyfigure__figure {
  position: relative;
  padding: 0 0 0.4em;
  font-weight: 500;
  font-size: 2rem;
  color: #000;
  text-align: center;
}

.reactMarkDown div.keyfigure div.keyfigure__figure strong {
  color: #d3145b;
  display: block;
  font-weight: 800;
  line-height: 80%;
  font-size: 6.5rem;
}

.reactMarkDown div.keyfigure--color1 div.keyfigure__figure strong {
  color: #d3145b;
}

.reactMarkDown div.keyfigure--color2 div.keyfigure__figure strong {
  color: #f67004;
}

.reactMarkDown div.keyfigure--color3 div.keyfigure__figure strong {
  color: #6d2861;
}

.reactMarkDown div.keyfigure div.keyfigure__content {
  font-size: 1.7rem;
  line-height: 150%;
  text-align: center;
  color: #1e1e1e;
  font-weight: 400;
}

.reactMarkDown div.keyfigures {
  margin-left: -5rem;
  color: #1e1e1e;
}

.reactMarkDown div.keyfigures div.col-lg-4 {
  flex: 0 0 33.33333%;
  margin-bottom: 30px;
}

.reactMarkDown div.keyfigures div.keyfigure__pic {
  margin: 0 0 1.6rem;
}

.reactMarkDown div.keyfigures div.keyfigure__pic img {
  display: block;
  margin: 0 auto;
}

.reactMarkDown div.cta-block {
  margin: 2em 0 3em;
  padding: 4px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background: $linear-gradient-background;
}

.reactMarkDown div.cta-block__inner {
  padding: 2.8em 2em 1.2em 2.6em;
  display: table;
  width: 100%;
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 1.6rem;
  line-height: 160%;
}

.reactMarkDown div.cta-block__icon {
  width: 10%;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding-right: 4em;
  padding-left: 0.65em;
}

.reactMarkDown div.cta-block__icon img {
  vertical-align: middle;
  border-style: none;
}

.reactMarkDown div.cta-block__content {
  display: table-cell;
  vertical-align: middle;
}

.reactMarkDown div.cta-block__content h3.cta-block__title {
  margin: 0 0 0.95em !important;
  padding-bottom: 0.675em;
  font-family: Montserrat, sans-serif;
  font-size: 2.7rem;
  font-weight: 800;
  line-height: 120%;
  color: #000;
}

.reactMarkDown div.cta-block__content h3.cta-block__title::after {
  margin-top: 10px;
  width: 38px;
  height: 6px;
  background: #da5f05;
  bottom: 0;
  left: 0;
  z-index: 5;
  content: '';
  display: block;
  box-sizing: border-box;
}

.reactMarkDown div.cta-block__footer a.btn {
  text-transform: uppercase;
  display: inline-block;
  border: none;
  border-radius: 3rem;
  padding: 2px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 1.8;
  letter-spacing: 0.025em;
  color: #25262c;
  background: $linear-gradient-background;
  text-decoration: underline;
}

.reactMarkDown div.cta-block__footer a.btn:hover {
  color: #d3145b;
}

.reactMarkDown div.cta-block__footer a.btn span.btn-inner {
  background-color: #fff;
  padding: 0.85rem 2rem;
  min-width: 15.5rem;
  border-radius: 3rem;
  transition: all 0.2s ease-in-out;
  text-align: center;
  display: inline-block;
}

.reactMarkDown div.cta-block__footer {
  text-align: left;
  box-sizing: border-box;
}

.reactMarkDown blockquote.verbatim {
  position: relative;
  z-index: 100;
  margin: 2.5em 0 2.3em;
  padding: 4px;
  margin-left: 50px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  width: 100%;
  transform: translateX(-50px);
  background: $linear-gradient-background;
}

.reactMarkDown blockquote.verbatim::before {
  content: '\201C';
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  font-size: 8.5rem;
  line-height: 120%;
  color: #484859;
  position: absolute;
  top: 30px;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
}

.verbatim::before {
  background: linear-gradient(0deg, #1d3765, #6d2861 65%, #d3145b 70%, #c4583d 78%, #f29222);
}

.reactMarkDown blockquote.verbatim div.verbatim__inner {
  position: relative;
  padding: 4.5em 1em 1.8em;
  background-color: #fff;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 160%;
  color: #484859;
}

.reactMarkDown blockquote.verbatim div.verbatim__inner::after {
  box-sizing: border-box;
  content: '';
  display: block;
  width: 64px;
  height: 1px;
  background-color: #929295;
  margin-left: 70px;
  position: absolute;
  top: 5.6rem;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
}
.reactMarkDown blockquote.verbatim div.verbatim__inner::before {
  box-sizing: border-box;
  margin-left: -70px;
  content: '';
  display: block;
  width: 64px;
  height: 1px;
  background-color: #929295;
  position: absolute;
  top: 5.6rem;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
}

.reactMarkDown blockquote.verbatim div.verbatim__inner p {
  margin: 0 0 2.6em;
}

.reactMarkDown blockquote.verbatim div.verbatim__inner p.author {
  margin: 0;
  font-family: Montserrat, sans-serif;
  line-height: 125%;
  font-weight: 800;
  font-size: 1.8rem;
  color: #484859;
}

.reactMarkDown blockquote.verbatim div.verbatim__inner p.fx {
  font-weight: 500;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #d3145b;
  margin: 0;
  font-family: Montserrat, sans-serif;
  line-height: 125%;
}

.reactMarkDown div.verbatim-with-pic {
  width: 100%;
  margin-left: 50px;
  transform: translateX(-50px);
  position: relative;
  margin-bottom: 2em;
}

.reactMarkDown div.verbatim-with-pic blockquote.verbatim {
  z-index: 100;
  position: absolute;
  padding: 4px;
  width: 50%;
  top: 0;
  right: 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  background: $linear-gradient-background;
}

.reactMarkDown div.verbatim-with-pic div.verbatim-with-pic__pic {
  width: 600px;
  height: auto;
  margin: 0;
}

.wp-block-table table {
  width: 100%;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 2em;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.alignleft {
  float: left;
  margin: 0 1em 1em 0;
}

.reactMarkDown div.verbatim-with-pic__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  display: block;
  border-style: none;
}
// .ArticleContentBody
.ArticleContentBody {
  margin: $spacer-section 0;
  display: flex;
  justify-content: center;
}
