// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .ConvocationFileInput

.ConvocationFileInput {
  flex-grow: 1;
  .delete-button {
    margin-left: 15px;
  }
}
