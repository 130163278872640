// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .ModalContactHeader
.ModalContactHeader {
  margin-bottom: -3rem !important;
  position: relative;
  z-index: 4;

  .Figure {
    margin-bottom: $spacer-base;
    margin-left: 4rem;
    height: 12rem;
    width: 12rem;
    box-shadow: 0 0 0 1rem $body-bg;
  }
  @include media-breakpoint-down(sm) {
    & > [class*='col'] {
      align-items: center;

      &:first-child {
        order: 2;
      }
      .Figure {
        margin-left: 0;
        align-self: center;
      }
    }
  }
}

// .ModalContactContent
.ModalContactContent {
  max-height: 80%;
  overflow: auto;
  .Card {
    .card-header {
      padding-top: 3rem;
      font-size: $font-size-lg;
    }
  }
}
