.file-browser {
  height: 375px;
  .chonky-infoContainer {
    display: none;
    
  }
  div[class*='gridFileEntryNameContainer-'] {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  div[class*='previewFile-'] {
    height: 100px;
  }
  div[class*='gridFileEntry-'] {
    display: block;
  }
}
