@import '../../../styles/config/Config';

.NotificationItem {
  cursor: pointer;
  
  &.Highlight {
    & > .NotificationBody {
      border-left: 5px solid lighten($secondary, 0);
    }
  }
  
  & > .NotificationBody {
    padding: 1.5rem;

    &:hover {
      background-color: $ultralightgrey;
      box-shadow: $box-shadow-sm;
    }
  }
  
  & > hr {
    margin: 0.5rem;
  }
}
