// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

// DEDICATED STYLES
// --------------------------------------------------

// .Overlay
.Overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: $spacer-base;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  transition: $transition-base;
  background: rgba($ultradarkgray, $alpha-base);
  color: color-yiq($ultradarkgray);

  // a#{&}
  @at-root {
    a:hover & {
      background: rgba($ultradarkgray, $alpha-base-hover);
    }
  }
  .Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;

    & + * {
      margin-top: $spacer-base;
    }
  }
}

// // .Colors
// @each $color, $value in $theme-colors {
//   .Overlay-#{$color} {
//     color: color-yiq($value);
//     background: rgba($value, $alpha-base);

//     // a#{&}
//     @at-root {
//       a:hover & {
//         background: rgba($value, $alpha-base-hover);
//       }
//     }
//   }
// }
