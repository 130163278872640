
.FileViewer {
  margin-left: auto;
  margin-right: auto;
  .pg-viewer-wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .pg-driver-view {
    margin-top: auto;
    margin-bottom: auto;
  }
  .pdf-canvas, .video-container,  {
    display: flex;
  }
  canvas, video {
    margin-left: auto;
    margin-right: auto;
  }
}


