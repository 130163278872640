// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import 'bootstrap/scss/badge';

// DEDICATED STYLES
// --------------------------------------------------

// .Badge
.Badge {
  border-width: $btn-border-width;
  border-style: solid;
  font-weight: $font-weight-normal;

  @include badge-size($badge-padding-y, $badge-padding-x, $badge-font-size);
}

// .Size
.Badge-sm {
  @include badge-size($badge-padding-y-sm, $badge-padding-x-sm, $badge-font-size-sm);
}
.Badge-md {
  @include badge-size($badge-padding-y-md, $badge-padding-x-md, $badge-font-size-md);
}
.Badge-lg {
  @include badge-size($badge-padding-y-lg, $badge-padding-x-lg, $badge-font-size-lg);
}

// .Badges
.Badges {
  .Badge {
    margin-bottom: 0.5rem;
  }
}

// .Colors
@each $color, $value in $theme-colors {
  .Badge-#{$color} {
    @include badge-variant($value);
    @include badge-variant-patch($value);
  }
}
@each $color, $value in $theme-colors {
  .Badge-outline-#{$color} {
    @include badge-outline-variant($value);
  }
}
@each $color, $value in $theme-colors {
  .Badge-filled-outline-#{$color} {
    @include badge-outline-filled-variant($value);
  }
}
