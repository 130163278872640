@import 'src/styles/config/Config';

.Toastify__toast--info {
  background: $primary;
}

.Toastify__toast--success {
  background: $accepted;
}

.Toastify__toast--warning {
  background: $pending;
}

.Toastify__toast--error {
  background: $refused
}
