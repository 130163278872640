// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// BOOTSTRAP IMPORT
// --------------------------------------------------

// Components
@import 'bootstrap/scss/input-group';

// PROJECT STYLES
// --------------------------------------------------

// .TextInput
.TextInput {
  .form-control {
    box-shadow: $box-shadow-input;

    &.is-invalid {
      &[name=password] {
        background-position: right calc(0.375em + 1.975rem) center;
      }

      &,
      &::placeholder {
        color: $danger;
      }
    }
  }

  .form-control[readonly] {
    background-color: $lightgrey;
  }
}


