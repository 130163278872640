// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// MIXINS
// --------------------------------------------------

// DEDICATED STYLES
// --------------------------------------------------

// .Row
.Row {
  flex: 1 1 100%;

  @include make-grid();

  &.grid {
    align-items: stretch;

    & > [class*='col'] {
      display: flex;
      flex-direction: column;

      & > * {
        flex-grow: 1;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &.flex-column,
  &.flex-column-reverse {
    align-items: stretch;

    & > [class*='col-'] {
      max-width: none !important;
    }
    & > .col-auto {
      width: 100%;
    }
  }
  @each $breakpoint in map-keys($grid-breakpoints) {
    &.flex-#{$breakpoint}-column,
    &.flex-#{$breakpoint}-reverse {
      align-items: stretch;

      & > [class*='col-'] {
        max-width: none !important;
      }
      & > .col-auto {
        width: 100%;
      }
    }
  }
  &.flex-nowrap {
    &.grid {
      & > [class*='col'] {
        display: flex;
        flex-direction: column;

        & > * {
          flex-grow: 1;
        }
      }
      &.flex-column,
      &.flex-column-reverse {
        & > [class*='col-'] {
          flex-direction: row;
        }
      }
      @each $breakpoint in map-keys($grid-breakpoints) {
        &.flex-#{$breakpoint}-column,
        &.flex-#{$breakpoint}-reverse {
          & > [class*='col-'] {
            flex-direction: row;
          }
        }
      }
    }
  }
  &.flex-fill {
    & > [class*='col-'] {
      flex-grow: 1 !important;
      flex-shrink: 1 !important;
      max-width: none;
    }
  }
  &.align-items-stretch {
    & > [class*='col'] {
      display: flex;
      flex-direction: column;

      & > * {
        flex-grow: 1;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &.justify-content-between,
  &.justify-content-arround,
  &.justify-content-start,
  &.justify-content-end {
    & > [class*='col'] {
      flex-grow: 0;
    }
  }

  // .gutters
  @each $width, $value in $grid-gutter-widths {
    &.gutters-#{$width} {
      @include make-grid($width);
    }
  }
}
