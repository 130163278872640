// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// BOOTSTRAP IMPORT
// --------------------------------------------------

// Components
@import 'bootstrap/scss/_card.scss';

// MIXIN
// --------------------------------------------------

// @mixin make-card-padding {
@mixin make-card-padding($padding-x, $padding-y) {
  .card-header,
  .card-body,
  .card-footer {
    padding: ($padding-y/2) $padding-x;

    &:first-child {
      padding-top: $padding-y;
    }

    &:last-child {
      padding-bottom: $padding-y;
    }
  }
}

// PROJECT STYLES
// --------------------------------------------------

// .Card
.Card {
  overflow: visible;
  
  // [class*='box-shadow']
  &[class*='box-shadow'] {
    border-width: 0;
  }
  
  // .border-radius
  @each $radius, $radiusValue in $border-radius-map {
    &.border-radius-#{$radius} {
      & > .Figure,
      & > .card-img,
      & > .card-header,
      & > .card-body,
      & > .card-footer {
        &:first-child {
          border-top-left-radius: $radiusValue;
          border-top-right-radius: $radiusValue;
        }
        
        &:last-child {
          border-bottom-left-radius: $radiusValue;
          border-bottom-right-radius: $radiusValue;
        }
      }
    }
  }
  
  @include make-card-padding($card-spacer-x, $card-spacer-y);
  
  .card-header,
  .card-body,
  .card-footer {
    border-width: 0;
    background: transparent;
  }
  
  &.padding-none {
    @include make-card-padding(0, 0);
  }
  
  &.padding-sm {
    @include make-card-padding($card-spacer-x, $card-spacer-y-sm);
  }
  
  &.padding-md {
    @include make-card-padding($card-spacer-x, $card-spacer-y);
  }
  
  &.padding-lg {
    @include make-card-padding($card-spacer-x, $card-spacer-y-lg);
  }
  
  .card-title {
    margin-bottom: $spacer-base;
    font-weight: $font-weight-bold;
  }
  
  .card-header {
    .card-title {
      margin: 0;
    }
  }
  
  & > .Row {
    & > [class*='col'] {
      display: flex;
      flex-direction: column;
      
      .card-body,
      .card-header,
      .card-footer {
        padding-left: $card-spacer-x;
        padding-right: $card-spacer-x;
        
        &:only-child {
          flex: 1 1 100%;
        }
      }
      
      .Figure {
        min-height: 100%;
        flex: 1 0 auto;
        
        @include object-fit(cover);
      }
      
      &:first-child {
        .card-body,
        .card-header,
        .card-footer,
        .Figure,
        .card-img {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        
        // .border-radius
        @each $radius, $radiusValue in $border-radius-map {
          @at-root {
            .border-radius-#{$radius}#{&} {
              & > .Figure,
              & > .card-img,
              & > .card-header,
              & > .card-body,
              & > .card-footer {
                border-top-left-radius: $radiusValue;
                border-bottom-left-radius: $radiusValue;
              }
            }
          }
        }
      }
      
      &:last-child {
        .card-body,
        .card-header,
        .card-footer,
        .Figure,
        .card-img {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          
          // .border-radius
          @each $radius, $radiusValue in $border-radius-map {
            @at-root {
              .border-radius-#{$radius}#{&} {
                & > .Figure,
                & > .card-img,
                & > .card-header,
                & > .card-body,
                & > .card-footer {
                  border-top-right-radius: $radiusValue;
                  border-bottom-right-radius: $radiusValue;
                }
              }
            }
          }
        }
      }
    }
  }
  
  &.CardClickable {
    cursor: pointer;
    transition: $transition-base;
    
    &:hover {
      transform: scale(1.02);
    }
  }
  
  &.CardBanner {
    .card-header {
      padding-left: 0;
      
      .BannerContent {
        font-weight: bold;
        color: $white;
        padding: 0.5rem 1.5rem;
        display: inline;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
  
@each $color, $value in $theme-colors {
  .Banner-#{$color} {
    .BannerContent {
      background-color: $value;
    }
  }
}
