@import 'src/styles/config/Config.scss';

.loader {
  background-color: #e5e5e590;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.loader-text {
  font-weight: $font-weight-bold;
  margin-bottom: 20px;
  color: $darkblue;
}
