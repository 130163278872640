@import 'src/styles/config/Config.scss';
.FilesContainer {
  max-height: 300px;
  overflow: auto;
}

.nav-item {
  z-index: 1;
}

.custom-button {
  max-height: 40px;
}

.nav-tabs {
  border: none !important;
}

.add-file-button {
  max-height: 42px;
  margin-left: $spacer-base;
  margin-right: $spacer-base;
}

.Picto {
  margin: auto $spacer-base auto $spacer-base;
  color: $primary;
  &:hover {
    color: lighten($primary, 60);
    cursor: pointer;
  }
}

.ManagerTreeItem .ant-tree-node-content-wrapper {
  border-left: $primary 3px solid;
}

.ManagerTreeItem {
  input[name='title'] {
    max-width: 70%;
    margin-left: $spacer-section;
    margin-right: $spacer-section;
  }
}

.ant-tree-node-content-wrapper {
  .ant-tree-title {
    .col {
      .Title {
        display: flex;
        height: fit-content;
        margin: auto;
        .PositionContainer {
          display: flex;
          color: white;
          margin-right: 1rem;
          height: fit-content;
          

          .Position, .Icon {
            display: flex;
            padding: 0 0.5rem;
            min-width: 2rem;
            min-height: 2rem;
            justify-content: center;
            align-items: center;
          }

          .Position {
            font-size: 1.5rem;
            background-color: $darkblue;
          }

          .Icon {
            font-size: 1rem;
            font-weight: bolder;
            background-color: $greylight;
          }

          &.Primary {
            .Position {
              background-color: $orange;
            }

            .Icon {
              background-color: $darkblue;
            }
          }
        }
      }
    }
  }

  &.ant-tree-node-content-wrapper-open {
    .Icon .IconSvg {
      transform: rotateZ(90deg);
    }
  }
}
