// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .Calendar
.Calendar {
  margin-top: 20px;
  position: relative;
  text-align: center;
  display: flex-end;
  flex-direction: column;

  .top-content {
    height:290px;
  }

  .bot-content {
    height: 100px;
    margin-top: 55px;
  }

  .CalendarHeader,
  .CalendarDays {
    flex: 0 0 auto !important;
  }
  .CalendarHeader {
    font-size: $font-size-xl;
  }
  .CalendarDays {
    color: $secondary;
    font-size: $font-size-md;
  }
  .CalendarCells {
    flex: 1 1 100% !important;
    color: $text-simple;
  }
}

// .CalendarCard
.CalendarCard {
  height: 550px;
  .card-body {
    display: flex;
    flex-direction: column;
    .Calendar {
      flex: 1 1 100%;
    }
  }
}
