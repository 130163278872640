// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .EditableProfilePicture
.EditableProfilePicture {
  min-width: 100px;
  .add-picture-badge {
    bottom: 0.5rem;
    right: 0.5rem;
  }
}
