// IMPORT BOOTSTRAP MIXINS
// --------------------------------------------------
@import 'bootstrap/scss/_mixins.scss';

// DEFAULT MIXINS
// --------------------------------------------------

// @mixin clearfix
@mixin clearfix() {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}

// @mixin make-container-padding - For each breakpoint, define the padding of the container in a media query
@mixin make-container-padding-old($gutters: $grid-gutter-widths, $additional-paddings: $container-additional-paddings) {
  @each $breakpoint in map-keys($additional-paddings) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      $additional-padding: map-get($additional-paddings, $breakpoint);
      padding-right: ($gutter / 2) + $additional-padding !important;
      padding-left: ($gutter / 2) + $additional-padding !important;
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the padding of the container in a media query
@mixin make-container-padding(
  $gutter-width: $grid-gutter-width-base,
  $gutter-max-widths: $grid-gutter-max-widths,
  $additional-paddings: $container-additional-paddings
) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-max-width: map-get($gutter-max-widths, $breakpoint);
      $additional-padding: map-get($additional-paddings, $breakpoint);
      $value: 0;

      @if $gutter-max-width > $gutter-width {
        $value: $gutter-width;
      } @else {
        $value: $gutter-max-width;
      }
      padding-right: (($value / 2) + $additional-padding) !important;
      padding-left: (($value / 2) + $additional-padding) !important;
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the vertical padding of the grid in a media query
@mixin make-gutters-size-x(
  $width: lg,
  $gutter-widths: $grid-gutter-widths,
  $gutter-max-widths: $grid-gutter-max-widths
) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-max-width: map-get($gutter-max-widths, $breakpoint);
      $gutter-width: map-get($gutter-widths, $width);
      $value: 0;

      @if $gutter-max-width > $gutter-width {
        $value: $gutter-width;
      } @else {
        $value: $gutter-max-width;
      }
      padding-left: ($value / 2);
      padding-right: ($value / 2);
    }
  }
}

// @mixin make-container-padding - For each breakpoint, define the vertical padding of the grid in a media query
@mixin make-gutters-size-y(
  $width: lg,
  $gutter-widths: $grid-gutter-widths,
  $gutter-max-widths: $grid-gutter-max-widths
) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-max-width: map-get($gutter-max-widths, $breakpoint);
      $gutter-width: map-get($gutter-widths, $width);
      $value: 0;

      @if $gutter-max-width > $gutter-width {
        $value: $gutter-width;
      } @else {
        $value: $gutter-max-width;
      }
      padding-top: ($value / 2);
      padding-bottom: ($value / 2);
    }
  }
}

// @mixin make-row-size - For each breakpoint, define the horizontal margin of the grid in a media query
@mixin make-row-size($width: lg, $gutter-widths: $grid-gutter-widths, $gutter-max-widths: $grid-gutter-max-widths) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-max-width: map-get($gutter-max-widths, $breakpoint);
      $gutter-width: map-get($gutter-widths, $width);
      $value: 0;

      @if $gutter-max-width > $gutter-width {
        $value: $gutter-width;
      } @else {
        $value: $gutter-max-width;
      }
      margin-left: -($value / 2);
      margin-right: -($value / 2);
    }
  }
}

// @mixin make-grid-size - For each breakpoint, define the vertical margin of the grid in a media query
@mixin make-grid-size($width: lg, $gutter-widths: $grid-gutter-widths, $gutter-max-widths: $grid-gutter-max-widths) {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $gutter-max-width: map-get($gutter-max-widths, $breakpoint);
      $gutter-width: map-get($gutter-widths, $width);
      $value: 0;

      @if $gutter-max-width > $gutter-width {
        $value: $gutter-width;
      } @else {
        $value: $gutter-max-width;
      }
      margin-top: -($value / 2);
      margin-bottom: -($value / 2);
    }
  }
}

// @mixin make-grid
@mixin make-grid($width: lg) {
  @include make-row-size($width);

  & > [class*='col'] {
    @include make-gutters-size-x($width);
  }
  &.grid {
    @include make-grid-size($width);
    align-items: stretch;

    & > [class*='col'] {
      @include make-gutters-size-y($width);
    }
  }
}

// @mixin make-container-padding - Has overlay
@mixin has-overlay($color: $body-bg, $bg: $brand-info, $alpha: $alpha-base) {
  $active-bg: darken($bg, $variant-button-hover);
  background: rgba($bg, $alpha);
  color: $color;
  @at-root {
    a & {
      transition: $transition-base;
      color: $color;
    }
  }
  @at-root {
    a:hover & {
      background: rgba($active-bg, $alpha);
    }
  }
}

// @mixin bg-variant-patch - Include color YIG to obtain white text without .text-white className
@mixin bg-variant-text($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: color-yiq($color);

    @if color-yiq-test($color) == 'light' {
      a:not([class*='btn']):not([class*='badge']:not([class*='card'])) {
        color: $body-bg;

        @include hover() {
          color: darken($body-bg, $emphasized-link-hover-darken-percentage);
        }
      }
    }
  }
}

// @mixin object-fit
@mixin object-fit($fit: 'cover') {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  &:after {
    content: '';
    display: block;
    height: 0;
    width: 100%;
  }
  img,
  span {
    position: absolute;
    object-fit: $fit;
    max-width: none;
    width: 100%;
    height: 100%;
  }
  
  svg {
    position: absolute;
    object-fit: $fit;
    max-width: none;
    width: 50%;
    height: 50%;
  }
}

// PROJECT MIXINS
// --------------------------------------------------

// @mixin button-selected-variant
@mixin button-selected-variant($color) {
  $text-color: color-yiq($color);

  &.selected {
    color: $text-color !important;
    background-color: $color !important;
  }
  &.selected:hover {
    border-color: darken($color, $variant-button-hover) !important;
    background-color: darken($color, $variant-button-hover) !important;
  }
}

// @mixin badge-variant
@mixin badge-variant-patch($color) {
  border-color: $color;

  @at-root a#{&} {
    @include hover-focus() {
      background-color: darken($color, $variant-bg-hover);
      border-color: darken($color, $variant-bg-hover);
    }
  }
}

// @mixin badge-outline-variant
@mixin badge-outline-variant($color) {
  color: $color;
  background: transparent;
  border-color: $color;

  @at-root a#{&} {
    @include hover-focus() {
      color: darken($color, $variant-text-hover);
      border-color: darken($color, $variant-text-hover);
    }
  }
}

// @mixin badge-outline-variant
@mixin badge-outline-filled-variant($color) {
  color: $color;
  background: $body-bg;
  border-color: rgba(lighten(saturate($color, 20%), 20%), 0.4);

  @at-root a#{&} {
    @include hover-focus() {
      color: darken($color, $variant-text-hover);
      background: rgba($color, $alpha-base);
      border-color: darken($color, $variant-text-hover);
    }
  }
}

// @mixin badge-size
@mixin badge-size($padding-y, $padding-x, $font-size) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
}
