// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .CalendarCells
.CalendarCells {
  display: flex;
  flex-direction: column;

  & > .Row {
    flex: 1 1 100%;

    & > [class*='col'] {
      flex: 0 0 4rem;
    }
  }
  .cell {
    transition: 0.25s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &:hover {
    background: var(--bg-color);
  }

  .number {
    flex: 0 0 auto !important;
    width: 4rem;
    height: 4rem;
    border-radius: 10rem;
    white-space: nowrap;
    font-size: 1.4rem;
    line-height: 1;
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.legend {
      width: 3rem;
      height: 3rem;
    }

    .NotificationDot {
      position: absolute;
      left: 0.1rem;

      &.DotMain {
        top: 0.1rem;
      }
      &.DotOther {
        bottom: 0.1rem;
      }
    }
  }
  .selected {
    .number {
      background-color: $secondary;
      color: $body-bg;
    }
  }
  .disabled {
    color: $text-muted;
    cursor: default;
  }
  .date-invitations {
    .number {
      background-color: rgba($secondary, 0.1);
    }
  }
  .date-meetings-non-invited {
    .number {
      background-color: rgba($lightgrey, 0.5);
    }
  }
  .cell-different-month {
    opacity: 0.4;
  }
}
