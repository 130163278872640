// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

$bar-size: 18rem;
$bar-md-size: 8rem;

.LoginTopBar {
  background-color: $orange;
  height: $bar-size;
  display: flex;
  align-items: center;

  
  .Logo {
    width: $bar-size;
    background-color: $white;
    height: 100%;
    text-align: center;
    
    span {
      margin: auto;
      height: 100%;
    }
  
    svg {
      display: block;
      width: 100%;
      height: 100%;
      
    }
  }
  
  .LoginCover {
    max-height: $bar-size * 0.5;
    left: 0;
    right: 0;
  }
}



.LoginBody {
  padding: 0 $bar-size;
  
  .LoginCard {
    background-color: rgba($greylight, 0.25);
    height: 100%;
    width: 100%;
    margin: auto;

    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    
    form {
      h2 {
        font-weight: $font-weight-normal;
        font-size: $font-size-xl;
      }
      input {
        border: none;
        font-size: $font-size-lg;
        padding: 2rem;
        border-radius: $border-radius-lg !important;
        
        &::placeholder {
          color: $greylight;
          font-weight: $font-weight-normal;
          font-size: $font-size-lg;
        }
      }
      
      button {
        font-size: $font-size-lg;
        padding-left:  4rem;
        padding-right:  4rem;
      }
      .PasswordShowIcon {
        position: absolute;
        top: 13px;
        right: 2%;
        z-index: 3;
        cursor: pointer;
      }
    }
    .card-header {
      margin-top: $spacer-base * 2;
    }
  }
  
  .LoginText {
    text-align: justify;
    overflow: auto;
    .LoginTextLogo {
      height: 15rem;
      margin-top: $spacer-base;
    }
    
    p {
      font-size: $font-size-xl;
      font-weight: $font-weight-small;
      margin-top: $spacer-base / 2;
      margin-left: $spacer-base;
    }
  }
}

@include media-breakpoint-down(lg) {
  .LoginBody {
    padding: 0 $bar-size / 2;
  }
}
@include media-breakpoint-down(md) {
  .LoginTopBar {
    height: $bar-md-size;
    
    h1 {
      font-size: $font-size-xl * 1.4;
    }

    .Logo {
      width: $bar-md-size;
      padding: $spacer-base * 2;
      display: flex;
    }
    
    .Banner {
      .IconSvg {
        width: 2.5 * 1rem !important;
        height: 2.5 * 1rem !important;
      }
    }
  }
  .LoginBody {
    padding: 0 0;

    .LoginCard {
      width: 100%;
      border-radius: 0 !important;
    }

    .LoginText {
      p {
        font-size: $font-size-lg;
        margin-left: $spacer-base;
        margin-right: $spacer-base;
      }
    }
  }
}
