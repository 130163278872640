// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import 'bootstrap/scss/modal';

// PROJECT STYLES
// --------------------------------------------------

// .Modal
.Modal {
  max-height: 100%;
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: $modal-padding-y $modal-padding-x;

    @include media-breakpoint-down(xs) {
      padding-left: $modal-padding-x/2;
      padding-right: $modal-padding-x/2;
    }
  }
  .modal-content {
    max-height: 100%;

    .modal-body {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow: auto;
      width: 100%;
      & > .Figure {
        flex: 0 1 auto !important;
      }
    }
  }
}

.modal {
  @at-root {
    .modal-open & {
      display: flex !important;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 $modal-dialog-margin-y-sm-up;
    }
  }
}
.modal-dialog {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: auto;
  margin-top: auto;
  padding: $modal-dialog-margin-y-sm-up 0;
}

.modal-sm {
  width: $modal-sm;
}
.modal-md {
  width: $modal-md;
}
.modal-lg {
  width: $modal-lg;
}
.modal-xl {
  width: $modal-xl;
}

// .modal-full {
.modal-full {
  width: 100%;
  min-height: 90%;

  .modal-content {
    min-height: inherit;
  }

  .modal-dialog {
    height: 100%;
  }
}

// .modal-auto {
.modal-auto {
  width: auto;
}
