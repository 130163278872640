// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// html, body, root
html,
body,
#root {
  height: 100%;
  overflow-y: auto;
}

// .App
.App {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    gap: 0px 0px;
    grid-template-areas:
      'Navbar Topbar'
      'Navbar View';

    .Navbar {
      grid-area: Navbar;
    }
    .Topbar {
      grid-area: Topbar;
    }
    .View {
      grid-area: View;
    }
    
   
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  //@include gradient-y(transparent, rgba($primary, 0.2));
}

$scrollbarColor: $primary;
$scrollbarBackgroundColor: transparent;
/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $scrollbarColor $scrollbarBackgroundColor;
}
/* Works on Chrome, Edge, and Safari */

//For the Navbar scrollbar

.NavbarBody::-webkit-scrollbar {
  width: 0.5rem;
}

.NavbarBody::-webkit-scrollbar-thumb {
  background: $inactive;
  border-radius: 1rem;
}

.NavbarBody:hover::-webkit-scrollbar-thumb {
  background: $scrollbarColor;
}

.NavbarBody:hover::-webkit-scrollbar {
  width: 1rem;
}

.NavbarBody::-webkit-scrollbar-track {
  background: $scrollbarBackgroundColor;
}


//For the principal scrollbar

.ViewBody::-webkit-scrollbar {
  width: 1rem;
}

.ViewBody:hover::-webkit-scrollbar-thumb {
  background-color: $scrollbarColor;
  border: 0.25rem solid $scrollbarColor;
  border-radius: 1rem;
}

.ViewBody::-webkit-scrollbar-thumb {
  background-color:$inactive;
  border: 0.25rem solid white;
  border-radius: 1rem;
}

.ViewBody:hover::-webkit-scrollbar {
  width: 1rem;
}
