// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import 'bootstrap/scss/buttons';

// DEDICATED STYLES
// --------------------------------------------------

// .Button
.Button {
  font-weight: $font-weight-bold;
  text-decoration: none;
  display: flex;
  
  .hidden {
    visibility: hidden;
  }
  
}

// .Buttons
.Buttons {
  .Button {
    margin-bottom: $spacer-base;
  }
}

.button-icon {
  width: 40px;
  height: 40px;
}
