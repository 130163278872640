// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .Heading
.Heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: $spacer-base 0;
  white-space: nowrap;

  .title {
    font-weight: 700;
    white-space: pre-wrap;
  }

  a {
    color: $secondary;
    font-weight: 700;
  }

  i {
    font-size: 1.2rem;
  }

  @include media-breakpoint-down(sm) {
    .title {
      font-size: $mobile-title-font-size;
    }
    a {
      font-size: 1rem;
    }
  }
}


