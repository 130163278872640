// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';
@import 'antd/lib/select/style/index.css';

.ant-select-selector {
  box-shadow: $box-shadow-input;
  .ant-select-selection-placeholder {
    color: #495057;
  }
}
.ant-select-disabled {
  
  .ant-select-selector {
    background-color: $lightgrey !important;
  }
}
