// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .KioskArticles
.KioskArticleCard {
  background-color: transparent;
  text-align: center;
  display: flex;

  .card-body {
    width: 80%;
    margin: 0 auto 0 auto;
    padding: 0 !important;
  }

  .card-footer {
    border-top: 0.1rem $lightgrey solid;
    font-size: $font-size-md;
  }

  .Figure {
    svg {
      height: 125px;
      margin: $spacer-base * 2 auto $spacer-base * 2 auto;
    }
  }
  #Calque_textes_fondateurs{
    transform: scale(1.1);
  }
  #Calque_rapport_activite{
    transform: scale(0.85);
  }
  #Calque_notes_frais{
    transform: scale(0.95)
  }
  #Calque_donnees_fiancieres{
    transform: scale(0.9); 
  }
  #Calque_releves_decisions{
    transform: scale(0.85);
  }
  #Calque_lettre_instances{
    transform: scale(1.45);
  }
  #Calque_activite{
    transform: scale(0.95);
  } 
  #Calque_syntheses_ca{
    transform: scale(0.95);
  }
}

.KioskArticleCard-previewMode{
  background-color: transparent;
  text-align: center;
  display: flex;
  height:180px;
  max-width:95px;
  border: 2px solid black;
  .card-title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow:ellipsis
  }

  .card-title:hover{
    overflow: visible;
    -webkit-line-clamp: 4;
    text-overflow: none;
  }

  .card-body {
    width: 100%;
    margin: 0;
    padding: 0 !important;
  }

  .card-footer {
    border-top: 0.1rem $lightgrey solid;
    font-size: $font-size-sm;
  }

  .Figure {
    border-radius: 50%;
    height: 52%;
    width: auto;
    svg {
      height: 150px;
      margin: $spacer-base * 2 auto $spacer-base * 2 auto;
  }
  
}

  #Calque_textes_fondateurs{
    transform: scale(0.98);
  }
  #Calque_convention_objectifs{
    transform: scale(1.3);
    //filter:brightness(120%)
  }
  #Calque_releves_decisions{
    transform: scale(1.35);
  }
  #Calque_rapport_activite{
    transform: scale(1.3);
  }
  #Calque_notes_frais{
    transform: scale(0.95)
  }
  #Calque_donnees_fiancieres{
    transform: scale(1.15);
  }
  #Calque_activite{
    transform: scale(1.3);
  } 
  #Calque_syntheses_ca{
    transform: scale(0.95);
  }
  #Calque_lettre_instances{
    transform: scale(1.8);
  }
}

.kiosk-badge {
  display: flex;
  justify-content: flex-end;
  padding-right: 150px;
}

@include media-breakpoint-down(sm) {
  .card-title {
    font-size: $mobile-title-font-size * 0.8;
  }
}

.pillBadge {
  position: absolute;
  top: -2rem;
  min-width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
}


