// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .ContactCard
.ContactCard {
  text-align: center;
  padding: 0;
  width: 100%;
  min-height: 27rem;

  .card-body {
    padding-left: $card-spacer-x;
    padding-right: $card-spacer-x;
  }
  .Figure {
    margin: 0 auto $spacer-base;
    height: 8rem;
    width: 8rem;
  }
  .card-title {
    margin-bottom: $spacer-base/2;
  }
  .card-text {
    margin: 0;
    font-size: $font-size-sm;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .Badges {
    text-align: left;
  }
  .ContactBadge {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 $spacer-base * 0.5 0 0;
  }
}
