// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import 'bootstrap/scss/breadcrumb';

// SPECIFICS STYLES
// --------------------------------------------------

// .Breadcrumb
.Breadcrumb {
  display: flex;
  flex-shrink: 1;
  background-color: transparent;
  .breadcrumb {
    background-color: inherit;
    align-items: center;
    font-size: x-large;
    font-weight: 700;

    a {
      color: $orange;
    }


    .breadcrumb-item {
      color: $primary;
      
      &:last-child {
        white-space: pre-wrap;
      }
    }
    .breadcrumb-item + .breadcrumb-item::before {
      float: left;
      padding-right: 0.5rem;
      color: $primary;
      content: ">";
    }
  }
}
