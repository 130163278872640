// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// BOOTSTRAP IMPORT
// --------------------------------------------------

// Components
@import 'bootstrap/scss/dropdown';

// PROJECT STYLES
// --------------------------------------------------

// .DropdownToggle
.DropdownToggle {
  box-shadow: 0 0 8px 0.5px rgba(0, 0, 0, 0.3);
  font-weight: $font-weight-bold;
  background: $body-bg;
}

.DropdownMenu {
  max-height: 400px;
  overflow: auto;
}
