// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

// DEDICATED STYLES
// --------------------------------------------------

// .FigureAcronyme
.FigureAcronyme {
  flex: 1 1 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lighten(saturate($primary, 20%), 70%);
  border: solid 0.1rem lighten(saturate($primary, 20%), 60%);
  border-radius: 100%;
  color: $primary;
  text-transform: uppercase;
  font-size: 2rem;
}
