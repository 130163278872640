// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .InputWithLabel
.InputWithLabel {
  label {
    font-weight: $font-weight-light;
    font-size: $font-size-sm;
  }
}
