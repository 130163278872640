// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .NavbarHeader
.NavbarHeader {
  margin: $spacer-base;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 5rem;
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      height: 13.3rem;
    }
  }
  span {
    margin-top: 0.25rem;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
