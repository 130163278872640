// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .InstanceFilter
.InstanceFilter {
}

// .InstanceFilterTreeSelect
.InstanceFilterTreeSelect {
  width: 100%;
  cursor: pointer;

  & > * {
    display: block;
    width: 100%;
    height: $input-height;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
  }

  & > .ant-select-selector {
    overflow-y: auto;
    height: auto;
    max-height: 10vh;
    overflow-x: hidden;

  }
}
