// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .NavbarItem
.NavbarItem {
  display: flex;
  align-items: center;
  padding: 15px;
  white-space: nowrap;
  color: $text-simple;
  font-weight: $font-weight-medium;

  &:hover {
    color: $secondary;
    cursor: pointer;
    text-decoration: none;
    
    .Icon {
      color: transparentize($secondary, 0.5);
    }
  }

  .Icon {
    margin: 0 1rem;
    font-size: 1.5em;
    color: transparentize($text-simple, 0.5);
  }
  
  .Badge {
    margin-left: 1rem;
    margin-top: -2rem;
    min-width: 2.5rem;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// .NavbarItemSelected
.NavbarItemSelected {
  color: $secondary;
  border-left: 4px solid $secondary;
  // Pour que le texte ne soit pas trop excentré à droite.
  border-right: 4px solid transparent;
  font-weight: 600;

  .Icon {
    margin: 0 1rem;
    font-size: 1.5em;
    
    color: transparentize($secondary, 0.2);
  }
}
