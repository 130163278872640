// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .HeadingListGroup
.HeadingListGroup {
  overflow: hidden;
  display: flex;
  align-items: center;
  color: desaturate(lighten($dark, 25%), 50%);

  &:before,
  &:after {
    content: '';
    display: block;
    border-bottom: solid $border-width currentColor;
  }
  &:before {
    flex: 0 0 5%;
  }
  &:after {
    flex: 1 1 100%;
  }

  & > strong {
    flex: 0 0 auto;
    padding: 1rem;
    display: block;
    position: relative;
  }
}
