// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// SPECIFICS STYLES
// --------------------------------------------------

// .ExampleTitle
.ExampleTitle {
  margin: $spacer-section 0;
  text-transform: uppercase;
  color: $pink;

  &:before {
    content: '[ ';
  }
  &:after {
    content: ' ]';
  }

  + p {
    margin-top: -($spacer-section - $spacer-base);
  }
}

// .ExampleSubtitle
.ExampleSubtitle {
  margin: $spacer-section 0 $spacer-base 0;
  color: $purple;

  &:before {
    content: '{ ';
  }
  &:after {
    content: ' }';
  }
}

// .ExampleBlock
.ExampleBlock {
  padding: $spacer-base;
  text-align: center;
}

// #Icon
#Icon {
  .Icon,
  .IconSvg {
    font-size: 2rem;
  }
}
