// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .NotificationDot
.NotificationDot {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  background: transparent;

  &.DotMain {
    background: $secondary;
  }
  &.DotOther {
    background: $lightgrey;
  }
}
