.Container {
  height: 100%;
  .DropzoneContainer {
    height: 100%;
    
    span {
      margin: auto;
    }
  }
}
.BaseStyle {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  
  div {
    flex-grow: 1;
    justify-content: center !important;
  }
}

.ActiveStyle {
  border-color: #2196f3;
}

.AcceptStyle {
  border-color: #00e676;
}

.RejectStyle {
  border-color: #ff1744;
}
