// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import 'bootstrap/scss/custom-forms';

// PROJECT STYLES
// --------------------------------------------------


// je diminue la margin top pour centrer l'élément
.custom-control-label::before {
  top: 0 // de base à 0.25rem
}
.custom-switch .custom-control-label::after {
  top: 0.2rem // de base à 0.45rem
}

.custom-checkbox {
  .custom-control-label::after {
    top: 0.1rem // centrage du check
  }
}
