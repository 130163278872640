// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config';

// DEDICATED STYLES
// --------------------------------------------------

// .Figure
.Figure {
  width: 100%;
  position: relative;
  margin: 0;
  flex: 0 0 auto !important;
  display: flex;
  overflow: hidden;

  img,
  video {
    width: auto;
    max-width: 100%;
  }
  
  svg {
    padding: 0;
  }
  .Icon,
  .icon{
    &:only-child {
      position: absolute;
      top: 50%;
      left: 50%;
      height: $figure-icon-size;
      width: $figure-icon-size;
      margin-top: -$figure-icon-size/2;
      margin-left: -$figure-icon-size/2;
      color: $body-bg;

      svg {
        * {
          box-shadow: $box-shadow-lg;
        }
      }
    }
  }

  // [class*="ratio-"]
  &[class*='ratio-'] {
    @include object-fit(cover);
  }
  @each $ratio, $value in $figure-ratios {
    &.ratio-#{$ratio} {
      &:after {
          padding-top: 100% / $value ;
      }
    }
  }

  // [class*="occupation--"]
  &[class*='occupation--'] {
    justify-content: center;
    align-items: center;
  }
  @each $occupation, $value in $figure-occupations {
    &.occupation--#{$occupation} {
      img {
        max-width: $value;
        padding: (100% - $value)/2;
      }
    }
  }

  // .circle
  &.circle {
    border-radius: 20rem;
  }
}
