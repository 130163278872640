@import 'src/styles/config/Config.scss';

.DocumentCard {
  margin-top: 1em;
  word-break: break-word;

  .card-checkbox {
    height: 15px;
  }

  .card-body {
    padding: $card-spacer-y !important;
  }
  .DocumentInfoBlock {
    align-items: center;
    height: 100%;

    p {
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    a {
      color: unset;
    }

    i {
      font-size: 24px;
      margin: 0 1rem;
      cursor: pointer;
    }
  }
  .DocumentCardGrid {
    & > [class*='col'] {
      flex-direction: row;
    }
    .DocumentCardName {
      flex: 1 1 auto;
      font-size: $font-size-md;
      font-weight: bold;
      color: $text-simple;
      word-break: break-all;
    }
    .DocumentCardIcon {
      justify-content: center;
      margin: map-get($grid-gutter-widths, sm) 0;
    }
    .DocumentCardWeight,
    .DocumentCardActions {
      justify-content: flex-end;
      text-align: right;
    }
    .DocumentCardWeight {
      flex-basis: 10rem;
    }
    .DocumentCardActions {
      & > * {
        flex: 0 0 auto;
      }
    }
    &:not(.grid) {
      align-items: center;
    }
    &.grid {
      margin: 0;
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto 1fr 1fr auto;
      gap: 0 map-get($grid-gutter-widths, sm);
      grid-template-areas:
        'DocumentCardCheckbox DocumentCardActions DocumentCardActions DocumentCardActions'
        'DocumentCardIcon DocumentCardIcon DocumentCardIcon DocumentCardIcon'
        'DocumentCardName DocumentCardName DocumentCardName DocumentCardName'
        'DocumentCardDate DocumentCardDate DocumentCardWeight DocumentCardWeight';

      & > [class*='col'] {
        padding: 0;
      }
      .DocumentCardIcon {
        grid-area: DocumentCardIcon;
      }
      .DocumentCardName {
        grid-area: DocumentCardName;
      }
      .DocumentCardDate {
        grid-area: DocumentCardDate;
      }
      .DocumentCardWeight {
        grid-area: DocumentCardWeight;
      }
      .DocumentCardCheckbox {
        grid-area: DocumentCardCheckbox;
      }
      .DocumentCardActions {
        grid-area: DocumentCardActions;
      }
    }
  }

  .flip-card {
    background-color: transparent;
    width: 63px;
    height: 63px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    margin: auto;
    
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.5s;
      transform-style: preserve-3d;
      
      .flip-card-front, .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden; /* Safari */
        backface-visibility: hidden;
      }
      .flip-card-front {
        color: black;
      }

      /* Style the back side */
      .flip-card-back {
        background-color: white;
        color: black;
        transform: rotateY(180deg);
        font-size: 1.4em;
      }
    }
    &:hover .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}
