// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .MeetingCard
.MeetingCard {
  border-radius: 1.2rem !important;
  .card-title {
    font-weight: $font-weight-bolder;
  }
  .card-body {
    margin-bottom: $spacer-base;
  }
  .tasks-list {
    margin: 0;
  }
  .MeetingPointsOverview {
    margin: 0 0 -0.5rem 0;
    padding-left: 2rem;
    font-size: 1.3rem;
    line-height: 1.4em;
    max-width: 75%;
    color: $text-simple;
  }
  .DateCard {
    transform: translateX(1rem);
    margin-bottom: -4rem;
  }
}
