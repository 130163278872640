// CONFIG (NO SELECTORS GENARATED)
@import 'src/styles/config/Config';

// PROJECT STYLES
// --------------------------------------------------

// .MandateBadge
.MandateBadge {
  margin-bottom: 0.5rem;
  display: block;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Mandates {
  max-height: 100%;
  .MandatesBody {
    overflow: auto;
    max-height: 100%;
  }
}
