@import 'src/styles/config/Config.scss';

.DocumentCarrousel {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.ant-tree-node-content-wrapper {
  width: 100%;
}
