// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// IMPORT BOOTSTRAP
// --------------------------------------------------

// Components
@import 'bootstrap/scss/grid';

// DEDICATED STYLES
// --------------------------------------------------

// .Container
.Container {
  margin-left: 0;

  @include make-container-padding();
}
