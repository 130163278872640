// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// PROJECT STYLES
// --------------------------------------------------

// .CalendarHeader
.CalendarHeader {
  font-size: $h3-font-size;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .CalendarHeaderNav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .month {
      text-transform: capitalize;
      font-weight: $font-weight-bold;
      min-width: 9rem;
      text-align: center;
      margin: 0 auto 0 auto;
    }

    .actions {
      display: flex;

      .Button {
        background: $body-bg;
        padding: $btn-padding-y-sm;
        font-size: $font-size-md;
        color: darken($lightgrey, 20);
        border-color: transparent;

        &:not(:hover) {
          background: transparent;
        }
      }
    }
  }

  .year {
    padding-right: 30px;
    
  }
}
