// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

// DEDICATED STYLES
// --------------------------------------------------

// .View
.View {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  .ViewHead {
    flex: 0 0 auto;
    position: sticky;
    top: 0;

    .Section {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .ViewBody {
    flex: 1 1 100%;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .Section {
      &:first-child {
        padding-top: $spacer-section/2;
      }
    }
    
    .ViewFoot {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding: $spacer-base;
      align-items: flex-end;
      
      img {
        height: 8rem;
      }

      .FixedLinkLineHeight {
        line-height: $input-height;
      }
    }
  }
}
