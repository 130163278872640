// CONFIG (NO SELECTORS GENERATED)
@import 'src/styles/config/Config.scss';

@import 'react-quill/dist/quill.snow.css';

// PROJECT STYLES
// --------------------------------------------------
.Quill {
  margin-top: $spacer-base;
  margin-bottom: $spacer-base;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  
  .ql-toolbar {
    top:0;
    position: sticky;
    background-color: $white;
  }
  
  .ql-container {
    flex-grow: 20;
    overflow: auto;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
  }
  
  .ql-editor {
    flex-grow: 1;
  }
}
