// Import config file
@import 'src/styles/config/Config';

// Styles for .CardArticle class
.CardArticle {
  border-radius: 1.2rem !important;
  will-change: backdrop-filter;

  .CustomCardHeader {
    padding: 0 !important;
    background-repeat: round !important;
    border-top-left-radius: 1.2rem !important;
    border-top-right-radius: 1.2rem !important;
    overflow: hidden;
    transform: scale(0.99);
  }

  .card-subtitle {
    margin-top: $spacer;
  }

  .card-article-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: black;
  }

  //image container permitting to blur the background
  .image-container {
    backdrop-filter: blur(100px);
    height: 280px;
    width: 100%;
    border-top-right-radius: 1.2rem !important;
    border-top-left-radius: 1.2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-img-top {
    max-height: 100%;
    max-width: 100%;
    object-fit: scale-down;
    border-radius: unset;
  }

  // Remove specific CSS for "a" tags
  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }
}
